import React, { useEffect } from "react"

import Markdown from "react-markdown"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "@/components/elements/custom-link"
import { gsap, Power3, Bounce, Power4 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "./section3.css"
const data = {
  title: "Now let us help you to never run out of charge",
  description: "40% of all vehicles in India are going to be EVs BY 2030",
}
const HomeSection3 = () => {
  useEffect(() => {
    gsap.from(".blue-bg-3", {
      scrollTrigger: {
        trigger: ".section-3",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      scale: 0,
      ease: Power3.easeIn,
      duration: 0.5,
      autoAlpha: 0,
    })
    gsap.from(".title-3", {
      scrollTrigger: {
        trigger: ".blue-bg-3",
        start: "top 60%",
        toggleActions: "play none none none",
      },
      duration: 0.75,
      delay: 1,
      y: 120,
      autoAlpha: 0,
      ease: Power3.easeOut,
      stagger: 1,
    })
    gsap.from(".hr-3", {
      scrollTrigger: {
        trigger: ".blue-bg-3",
        start: "top 60%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 1,
      delay: 1.2,
    })
    gsap.from(".desc-3", {
      scrollTrigger: {
        trigger: ".blue-bg-3",
        start: "top 60%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 0.6,
      delay: 1.2,
    })
    gsap.from(".car-3", {
      scrollTrigger: {
        trigger: ".blue-bg-3",
        start: "top 60%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      x: 2000,
      duration: 1,
      delay: 1.5,
    })
    gsap.from(".flash-3", {
      scrollTrigger: {
        trigger: ".blue-bg-3",
        start: "top 60%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      x: 2000,
      duration: 1,
      delay: 1.5,
    })
  })

  return (
    <div className="section-3">
      <div
        className="lg:container flex flex-col md:flex-row items-center justify-between py-12 blue-bg-3 bg_container"
        style={{
          /* BackgroundPrimary */
          background: "#516EF1",
          borderRadius: "16px 16px 16px 16px",
          marginTop: "70px",
          // maxHeight: 580,
        }}
      >
        {/* <h1 className="h1">hello</h1> */}
        {/* Left column for content */}

        {/* Right column for the image */}
        <div className="flex-1 sm:pr-8 md:p-0">
          {/* Hero section label */}
          {/* <p className="hero-title text-evp-primary max-w-sm">{data.label}</p> */}
          {/* Big title */}
          <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title title-3 text-white heading">
            {data.title}
          </h1>
          {/* Description paragraph-last */}
          <hr
            className="my-4 md:my-6 hr-3"
            style={{ borderTop: "8px solid white", width: "64px" }}
          ></hr>
          <p className="text-xl mb-6 sub-text text-white desc-3">
            {data.description}
          </p>
          {/* Buttons row */}
        </div>
        {/* <Image
          media={data.picture}
          className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
        /> */}
        <div className="image-container">
          <img
            className="car-3"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/car_bcc6537ca6/car_bcc6537ca6.png"
            }
          ></img>
          <img
            className="flash-3"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/flash_9e39b2683b/flash_9e39b2683b.png"
            }
          ></img>
        </div>
      </div>
    </div>
  )
}

export default HomeSection3
