import React, { useEffect } from "react"

import Markdown from "react-markdown"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "@/components/elements/custom-link"
import { gsap, Power3, Bounce, Power4 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "./section4.css"
const data = {
  title: "It's easy as it can get",
  description:
    "In next few years there are going to be EV charging station at every 25km on highways",
}
const HomeSection4 = () => {
  useEffect(() => {
    gsap.from(".title-4", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.75,
      delay: 1,
      y: 120,
      autoAlpha: 0,
      ease: Power3.easeOut,
      stagger: 1,
    })
    gsap.from(".hr-4", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      x: 1000,
      duration: 1,
      delay: 0.6,
      ease: Power4.easeInOut,
    })
    gsap.from(".desc-4", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 0.6,
      delay: 1.4,
    })
    gsap.from(".step-1", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.4,
      autoAlpha: 0,
      ease: Power4.easeIn,
      delay: 1.5,
      scaleY: 0,
      transformOrigin: "top",
    })
    gsap.from(".vertical-line-1", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.4,
      autoAlpha: 0,
      ease: Power4.easeIn,
      delay: 1.7,
      scaleY: 0,
      transformOrigin: "top",
    })
    gsap.from(".step-2", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.4,
      autoAlpha: 0,
      ease: Power4.easeIn,
      delay: 2,
      scaleY: 0,
      transformOrigin: "top",
    })
    gsap.from(".vertical-line-2", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.4,
      autoAlpha: 0,
      ease: Power4.easeIn,
      delay: 2.3,
      scaleY: 0,
      transformOrigin: "top",
    })
    gsap.from(".step-3", {
      scrollTrigger: {
        trigger: ".section-4",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.4,
      autoAlpha: 0,
      ease: Power4.easeIn,
      delay: 2.6,
      scaleY: 0,
      transformOrigin: "top",
    })
  })

  return (
    <div
      className="container flex flex-col md:flex-row container-4 section-4"
      style={{
        /* BackgroundPrimary */
        borderRadius: "16px 16px 16px 16px",
        marginTop: "160px",
        maxHeight: "952px",
      }}
    >
      {/* <h1 className="h1">hello</h1> */}
      {/* Left column for content */}
      <div className="md:w-6/12 md:p-16">
        <div className="step-1 image">
          <img
            src={
              "https://storage.googleapis.com/ev-cms-uploads/step1_dec13d16c3/step1_dec13d16c3.png"
            }
          ></img>
          <div>
            <span>Sign up</span>
            <span>Sign up once so that we can save your preferences</span>
          </div>
        </div>
        <div>
          <img
            className="vertical-line-1"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/line_5576c6c587/line_5576c6c587.png"
            }
          ></img>
        </div>
        <div className="step-2 image">
          <img
            src={
              "https://storage.googleapis.com/ev-cms-uploads/step2_2a5fbcc194/step2_2a5fbcc194.png"
            }
          ></img>
          <div>
            <span>Choose your vehicle</span>
            <span>
              Choose the vehicle you want to charge, so that we can find the
              most relevant stations for you.
            </span>
          </div>
        </div>
        <img
          className="vertical-line-2"
          src={
            "https://storage.googleapis.com/ev-cms-uploads/line_5576c6c587/line_5576c6c587.png"
          }
        ></img>
        <div className="step-3 image">
          <img
            src={
              "https://storage.googleapis.com/ev-cms-uploads/step3_1e66b992cc/step3_1e66b992cc.png"
            }
          ></img>
          <div>
            <span>Find the charging stations</span>
            <span>
              Charging stations near you with all the details is displayed.
            </span>
          </div>
        </div>
      </div>

      {/* Right column for the image */}
      <div className="flex-1 sm:pr-8 md:p-0 lg:p-32">
        {/* Hero section label */}
        {/* <p className="hero-title text-evp-primary max-w-sm">{data.label}</p> */}
        {/* Big title */}
        <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title title-4 heading">
          {data.title}
        </h1>
        {/* Description paragraph-last */}
        <hr
          className="my-4 md:my-6 hr-4"
          style={{ borderTop: "8px solid var(--evp-primary)", width: "64px" }}
        ></hr>
        <p className="text-xl mb-6 sub-text text-evp-secondary desc-4">
          {data.description}
        </p>
        {/* Buttons row */}
      </div>
      {/* <Image
          media={data.picture}
          className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
        /> */}
    </div>
  )
}

export default HomeSection4
