import React, { useEffect } from "react"

import Markdown from "react-markdown"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "@/components/elements/custom-link"
import { gsap, Power3, Bounce, Power4 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "./section5.css"
const data = {
  title: "It's easy as it can get",
  description:
    "Did you know that EVs have emissions up to 43% lower than diesel vehicles",
}
const HomeSection5 = ({ data }) => {
  useEffect(() => {
    gsap.from(".blue-bg-5", {
      scrollTrigger: {
        trigger: ".section-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      duration: 0.6,
      delay: 1,
      transformOrigin: "top left",
      scale: 0,
    })

    gsap.from(".image-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      x: 1000,
      duration: 1,
      delay: 1.5,
      ease: Power4.easeOut,
    })

    gsap.from(".heading-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      y: 75,
      ease: Power3.easeOut,
      duration: 0.6,
      autoAlpha: 0,
      delay: 2,
    })

    gsap.from(".title-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      y: 175,
      ease: Power3.easeOut,
      duration: 0.6,
      delay: 3,
      opacity: 0,
    })

    gsap.from(".hr-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      x: -500,
      ease: Power4.easeOut,
      duration: 1,
      delay: 3,
    })

    gsap.from(".paragraph-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      x: -500,
      ease: Power4.easeOut,
      duration: 1,
      delay: 3,
    })

    gsap.from(".button-5", {
      scrollTrigger: {
        trigger: ".blue-bg-5",
        start: "top 90%",
        toggleActions: "play none none none",
      },
      duration: 1,
      delay: 3,
      opacity: -4,
    })

    // gsap.from(".h1", {
    //   scrollTrigger: {
    //     trigger: ".step-2",
    //     start: "top 90%",
    //     toggleActions: "play none none none",
    //   },
    //   x: -400,
    //   rotation: 360,
    //   duration: 1,
    // })
  })

  return (
    <div className="section-5">
      <div
        className="lg:container flex flex-col md:flex-row items-center justify-between py-12 blue-bg-5"
        style={{
          /* BackgroundPrimary */
          background: "#EFF1FE",
          borderRadius: "16px 16px 16px 16px",
          marginTop: "70px",
        }}
      >
        {/* <h1 className="h1">hello</h1> */}
        {/* Left column for content */}
        <div className="flex-1 sm:pr-8 md:p-0 lg:p-32">
          {/* Hero section label */}
          <p className="hero-title text-evp-primary max-w-sm heading-5">
            {data.label}
          </p>
          {/* Big title */}
          <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title title-5 heading">
            {data.title}
          </h1>
          {/* Description paragraph-5 */}
          <hr
            className="my-4 md:my-6 hr-5"
            style={{ borderTop: "8px solid var(--evp-primary)", width: "64px" }}
          ></hr>
          <p className="text-xl mb-6 sub-text text-evp-secondary paragraph-5">
            {data.description}
          </p>
          {/* Buttons row */}
          <div className="flex flex-row flex-wrap button-5 z-10 download-btns">
            {data.buttons.map((button, i) =>
              button.buttonImage ? (
                <CustomLink link={button} key={i}>
                  {button.buttonImage && (
                    // <Image placeholder="none" media={button.buttonImage} />
                    <img
                      src={
                        i == 0
                          ? "https://storage.googleapis.com/ev-cms-uploads/button_appstore_91af1c944b/button_appstore_91af1c944b.png"
                          : "https://storage.googleapis.com/ev-cms-uploads/button_googleplay_3bc17ba607/button_googleplay_3bc17ba607.png"
                      }
                      className="imgButton"
                      alt="button-googleplay"
                      border="0"
                    />
                    //                   https://ibb.co/ZBXT8Lj
                    // https://ibb.co/zZT59xv
                    // https://ibb.co/nRsKQy5
                  )}
                </CustomLink>
              ) : (
                <ButtonLink
                  button={button}
                  appearance={getButtonAppearance(button.type, "light")}
                  key={button.id}
                />
              )
            )}
          </div>
          {/* Small rich text */}
          <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
            <Markdown source={data.smallTextWithLink} />
          </div>
        </div>
        {/* Right column for the image */}
        {/* <Image
        media={data.picture}
        className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
      /> */}
        <img
          src={
            "https://storage.googleapis.com/ev-cms-uploads/mobile_bce2b98586/mobile_bce2b98586.png"
          }
          className="flex-shrink-0 object-contain w-full mt-6 md:mt-0 relative md:right-80 image-5"
        ></img>
      </div>
    </div>
  )
}

export default HomeSection5
