import React, { useEffect } from "react"
import { globalHistory } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "@/components/elements/custom-link"
import { gsap, Power3, Bounce, Power4 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import HomeSection2 from "@/components/sections/animated-home/section-2"
import "./index.css"
import HomeSection3 from "@/components/sections/animated-home/section-3"
import HomeSection4 from "@/components/sections/animated-home/section-4"
import HomeSection5 from "../components/sections/animated-home/section-5"
gsap.registerPlugin(ScrollTrigger)

const NotFoundPage = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
      },
    },
  } = data

  const HomeHeroData = {
    __component: "sections.hero",
    id: 1,
    title: "Find charging stations on the go!",
    label: "Now drive Worry free.",
    description:
      "Download the app now to access India's largest network of EV charging stations",
    smallTextWithLink: "",
    buttons: [
      {
        id: 3,
        url: "https://apps.apple.com/in/app/ev-plugs/id1588026145",
        newTab: true,
        text: "App Store",
        type: "primary",
        buttonImage: {
          id: 40,
          name: "button_appstore.png",
          alternativeText: "",
          caption: "",
          width: 130,
          height: 60,
          formats: null,
          hash: "button_appstore_7bf8bc9467",
          ext: ".png",
          mime: "image/png",
          size: 1.74,
          url: "/uploads/button_appstore_7bf8bc9467.png",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          created_at: "2021-08-05T21:34:48.994Z",
          updated_at: "2021-08-05T21:34:49.022Z",
        },
      },
      {
        id: 5,
        url: "https://play.google.com/store/apps/details?id=com.evplugs",
        newTab: true,
        text: "Playstore",
        type: "secondary",
        buttonImage: {
          id: 41,
          name: "button_googleplay.png",
          alternativeText: "",
          caption: "",
          width: 156,
          height: 60,
          formats: null,
          hash: "button_googleplay_44a7557340",
          ext: ".png",
          mime: "image/png",
          size: 3.8,
          url: "/uploads/button_googleplay_44a7557340.png",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          created_at: "2021-08-05T21:34:49.075Z",
          updated_at: "2021-08-05T21:34:49.116Z",
        },
      },
    ],
    picture: {
      id: 39,
      name: "hero-art.png",
      alternativeText: "",
      caption: "",
      width: 461,
      height: 729,
      formats: {
        thumbnail: {
          name: "thumbnail_hero-art.png",
          hash: "thumbnail_hero_art_2843a1daac",
          ext: ".png",
          mime: "image/png",
          width: 99,
          height: 156,
          size: 29.66,
          path: null,
          url: "/uploads/thumbnail_hero_art_2843a1daac.png",
        },
        small: {
          name: "small_hero-art.png",
          hash: "small_hero_art_2843a1daac",
          ext: ".png",
          mime: "image/png",
          width: 316,
          height: 500,
          size: 209.14,
          path: null,
          url: "/uploads/small_hero_art_2843a1daac.png",
        },
      },
      hash: "hero_art_2843a1daac",
      ext: ".png",
      mime: "image/png",
      size: 246.8,
      url: "/uploads/hero_art_2843a1daac.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      created_at: "2021-08-05T21:32:28.496Z",
      updated_at: "2021-08-05T21:32:28.547Z",
    },
  }
  const HomeFinalSectionData = {
    __component: "sections.hero",
    id: 5,
    title: "Download Now",
    label: "Let's get started",
    buttons: [
      {
        id: 19,
        url: "https://apps.apple.com/in/app/ev-plugs/id1588026145",
        newTab: true,
        text: "App Store",
        type: "primary",
        buttonImage: {
          id: 40,
          name: "button_appstore.png",
          alternativeText: "",
          caption: "",
          width: 130,
          height: 60,
          formats: null,
          hash: "button_appstore_7bf8bc9467",
          ext: ".png",
          mime: "image/png",
          size: 1.74,
          url: "/uploads/button_appstore_7bf8bc9467.png",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          created_at: "2021-08-05T21:34:48.994Z",
          updated_at: "2021-08-05T21:34:49.022Z",
        },
      },
      {
        id: 20,
        url: "https://play.google.com/store/apps/details?id=com.evplugs",
        newTab: true,
        text: "Playstore",
        type: "secondary",
        buttonImage: {
          id: 41,
          name: "button_googleplay.png",
          alternativeText: "",
          caption: "",
          width: 156,
          height: 60,
          formats: null,
          hash: "button_googleplay_44a7557340",
          ext: ".png",
          mime: "image/png",
          size: 3.8,
          url: "/uploads/button_googleplay_44a7557340.png",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          created_at: "2021-08-05T21:34:49.075Z",
          updated_at: "2021-08-05T21:34:49.116Z",
        },
      },
    ],
    picture: {
      id: 44,
      name: "art.png",
      alternativeText: "",
      caption: "",
      width: 1126,
      height: 599,
      formats: {
        thumbnail: {
          name: "thumbnail_art.png",
          hash: "thumbnail_art_0b1d7b1b0a",
          ext: ".png",
          mime: "image/png",
          width: 245,
          height: 130,
          size: 32.8,
          path: null,
          url: "/uploads/thumbnail_art_0b1d7b1b0a.png",
        },
        large: {
          name: "large_art.png",
          hash: "large_art_0b1d7b1b0a",
          ext: ".png",
          mime: "image/png",
          width: 1000,
          height: 532,
          size: 350.4,
          path: null,
          url: "/uploads/large_art_0b1d7b1b0a.png",
        },
        medium: {
          name: "medium_art.png",
          hash: "medium_art_0b1d7b1b0a",
          ext: ".png",
          mime: "image/png",
          width: 750,
          height: 399,
          size: 204.92,
          path: null,
          url: "/uploads/medium_art_0b1d7b1b0a.png",
        },
        small: {
          name: "small_art.png",
          hash: "small_art_0b1d7b1b0a",
          ext: ".png",
          mime: "image/png",
          width: 500,
          height: 266,
          size: 99.58,
          path: null,
          url: "/uploads/small_art_0b1d7b1b0a.png",
        },
      },
      hash: "art_0b1d7b1b0a",
      ext: ".png",
      mime: "image/png",
      size: 294.73,
      url: "/uploads/art_0b1d7b1b0a.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      created_at: "2021-08-10T05:52:12.628Z",
      updated_at: "2021-08-10T05:56:28.146Z",
    },
  }
  return (
    <>
      <SEO seo={{ title: "EV Plugs | Ride Now" }} global={strapiGlobal} />
      <Layout
        pageContext={{
          locale: defaultLocale,
          locales,
          defaultLocale,
          slug: "404",
        }}
        global={strapiGlobal}
      >
        <HomeHero data={HomeHeroData}></HomeHero>
        <HomeSection2></HomeSection2>
        <HomeSection3></HomeSection3>
        <HomeSection4></HomeSection4>
        <HomeSection5 data={HomeFinalSectionData}></HomeSection5>
      </Layout>
    </>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundQuer2 {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`
// 1
const HomeHero = ({ data }) => {
  const timeline = gsap.timeline()
  useEffect(() => {
    globalHistory.listen(() => {
      ScrollTrigger.refresh()
      gsap.registerPlugin(ScrollTrigger)
      ScrollTrigger.refresh()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    })
    timeline
      .from(".blue-bg", {
        duration: 0.3,
        autoAlpha: 0,
        transformOrigin: "top left",
        scale: 0,
      })
      .from(".mobile-background", {
        scale: 0,
        delay: 0.1,
      })
      .from(".mobile-view", {
        autoAlpha: 0,
        duration: 0.3,
        x: 500,
        ease: Power4.easeOut,
      })
      .from(".heading-1", {
        y: 100,
        ease: Power3.easeOut,
        duration: 0.3,
        autoAlpha: 0,
      })
      .from(".heading-2", {
        y: 75,
        ease: Power3.easeOut,
        duration: 0.3,
        autoAlpha: 0,
      })
      .from(".hr", {
        x: -201,
        ease: Bounce.easeOut,
        autoAlpha: 0,
        duration: 0.4,
      })
      .add("start")
      .from(
        ".paragraph",
        {
          duration: 0.1,
          autoAlpha: 0,
          stagger: 0.5,
        },
        "start"
      )
      .from(
        ".button",
        {
          duration: 1,
          autoAlpha: 0,
          stagger: 0.5,
        },
        "start"
      )
      // .from(".paragraph", {
      //   duration: 0.2,
      //   autoAlpha: 0,
      //   stagger: 0.5,
      // })

      // .from(".button", {
      //   duration: 1,
      //   autoAlpha: 0,
      //   stagger: 0.5,
      // })

      .from(".scroll-indicator", {
        duration: 1,
        autoAlpha: 0,
        y: -42,
        ease: Bounce.easeOut,
        repeat: -1,
        repeatDelay: 0.8,
      })
  })

  return (
    <main>
      <div
        style={{
          minHeight: "600px",
        }}
      >
        <div
          className="container flex flex-col md:flex-row items-center justify-between py-12 blue-bg"
          style={{
            /* BackgroundPrimary */
            background: "#EFF1FE",
            borderRadius: "16px 16px 512px 16px",
          }}
        >
          {/* Left column for content */}
          <div className="flex-1 sm:pr-8 md:p-0 lg:p-32">
            {/* Hero section label */}
            <p className="hero-title text-evp-primary max-w-sm heading-1 heading">
              {data.label}
            </p>
            {/* Big title */}
            <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title heading-2 heading">
              {data.title}
            </h1>
            {/* Description paragraph */}
            <hr
              className="my-4 md:my-6 hr"
              style={{
                borderTop: "8px solid var(--evp-primary)",
                width: "64px",
              }}
            ></hr>
            <p className="text-xl mb-6 sub-text text-evp-secondary paragraph">
              {data.description}
            </p>
            {/* Buttons row */}
            <div className="flex flex-row flex-wrap gap-4 button">
              {data.buttons.map((button, i) =>
                button.buttonImage ? (
                  <CustomLink link={button} key={i}>
                    {button.buttonImage && (
                      // <Image placeholder="none" media={button.buttonImage} />
                      <img
                        className="hero-button"
                        src={
                          i == 0
                            ? "https://storage.googleapis.com/ev-cms-uploads/button_appstore_91af1c944b/button_appstore_91af1c944b.png"
                            : "https://storage.googleapis.com/ev-cms-uploads/button_googleplay_3bc17ba607/button_googleplay_3bc17ba607.png"
                        }
                        alt="button-googleplay"
                        border="0"
                      />
                      //                   https://ibb.co/ZBXT8Lj
                      // https://ibb.co/zZT59xv
                      // https://ibb.co/nRsKQy5
                    )}
                  </CustomLink>
                ) : (
                  <ButtonLink
                    button={button}
                    appearance={getButtonAppearance(button.type, "light")}
                    key={button.id}
                  />
                )
              )}
            </div>
            {/* Small rich text */}
            <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
              <Markdown source={data.smallTextWithLink} />
            </div>
          </div>
          {/* Right column for the image */}
          {/* <Image
        media={data.picture}
        className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
      /> */}
          <img
            src="https://storage.googleapis.com/ev-cms-uploads/mobile_background_a01c38b0d3/mobile_background_a01c38b0d3.png"
            className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-sm mobile-background"
          ></img>
          <img
            src="https://storage.googleapis.com/ev-cms-uploads/mobile_full_f25e157507/mobile_full_f25e157507.png"
            className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-sm mobile-view"
          ></img>
        </div>

        {/* scroll indicator */}
        <img
          className="scroll-indicator"
          src="https://storage.googleapis.com/ev-cms-uploads/scroll_indicator_b53132ac11/scroll_indicator_b53132ac11.png"
        ></img>
      </div>
    </main>
  )
}
