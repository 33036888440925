import React, { useEffect } from "react"

import Markdown from "react-markdown"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "@/components/elements/custom-link"
import { gsap, Power3, Bounce, Power4, TweenLite, Sine, TweenMax } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "./section2.css"
gsap.registerPlugin(ScrollTrigger)

const data = {
  title: "Thank you! for choosing Sustainable driving",
  description:
    "Charging EVs have emissions up to 43% lower than diesel vehicles",
}
const HomeSection2 = () => {
  useEffect(() => {
    gsap.from(".mountains", {
      scrollTrigger: {
        trigger: ".container-2",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      scale: 0,
      duration: 1,
      autoAlpha: 0,

      ease: Power3.easeOut,
    })

    gsap.from(".birds", {
      scrollTrigger: {
        trigger: ".mountains",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 0.6,
      y: 100,
      delay: 1,
      ease: Power4.easeOut,
    })

    gsap.from(".sun", {
      scrollTrigger: {
        trigger: ".mountains",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 1,
      x: 100,
      y: 100,
      delay: 0.7,
    })

    gsap.from(".title-2", {
      scrollTrigger: {
        trigger: ".title-2",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      duration: 0.75,
      delay: 1.5,
      y: 120,
      autoAlpha: 0,
      ease: Power3.easeOut,
      stagger: 1.5,
    })

    gsap.from(".hr-2", {
      scrollTrigger: {
        trigger: ".hr-2",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 1,
      delay: 2,
    })

    gsap.from(".desc-2", {
      scrollTrigger: {
        trigger: ".desc-2",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 0.6,
      delay: 2,
    })

    gsap.from(".man", {
      scrollTrigger: {
        trigger: ".mountains",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      x: 1000,
      duration: 0.6,
      delay: 2.5,
    })

    gsap.fromTo(
      ".curtain",
      {
        scrollTrigger: {
          trigger: ".man",
          start: "top 80%",
          toggleActions: "play none none none",
        },
        autoAlpha: 1,
        x: -200,
        y: -200,
        duration: 7,
        delay: 0,
      },
      {
        scrollTrigger: {
          trigger: ".man",
          start: "top 80%",
          toggleActions: "play none none none",
        },
        autoAlpha: 1,
        x: 1500,
        y: -100,
        duration: 2,
        delay: 4,
      }
    )
    gsap.from(".curve", {
      scrollTrigger: {
        trigger: ".man",
        start: "top 80%",
        toggleActions: "play none none none",
      },
      autoAlpha: 0,
      duration: 0,
      delay: 1,
    })
  })

  return (
    <div>
      <div className="curve-wrapper">
        <img
          className="curve"
          src={
            "https://storage.googleapis.com/ev-cms-uploads/curve_9a345de0ce/curve_9a345de0ce.png"
          }
        ></img>
      </div>
      <div
        className="container flex flex-col md:flex-row container-2"
        style={{
          /* BackgroundPrimary */
          borderRadius: "16px 16px 16px 16px",
          marginTop: "270px",
        }}
      >
        {/* <h1 className="h1">hello</h1> */}
        {/* Left column for content */}
        <div>
          <img
            className="mountains"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/mountains_173817c69d/mountains_173817c69d.png"
            }
          ></img>
          <img
            className="sun"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/sun_94cdfd61fa/sun_94cdfd61fa.png"
            }
          ></img>
          <img
            className="birds"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/birds_47d25e8d6f/birds_47d25e8d6f.png"
            }
          ></img>
          <img
            className="man"
            src={
              "https://storage.googleapis.com/ev-cms-uploads/man_856bd437dc/man_856bd437dc.png"
            }
          ></img>
        </div>

        {/* Right column for the image */}
        <div className="flex-1 sm:pr-8 md:p-0 title-container">
          {/* Hero section label */}
          {/* <p className="hero-title text-evp-primary max-w-sm">{data.label}</p> */}
          {/* Big title */}
          <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title title-2 heading">
            {data.title}
          </h1>
          {/* Description paragraph-last */}
          <hr
            className="my-4 md:my-6 hr-2"
            style={{ borderTop: "8px solid var(--evp-primary)", width: "64px" }}
          ></hr>
          <p className="text-xl mb-6 sub-text text-evp-secondary desc-2">
            {data.description}
          </p>
          {/* Buttons row */}
        </div>

        {/* <Image
          media={data.picture}
          className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
        /> */}

        {/* curtain */}

        <div className="curtain"></div>
      </div>
    </div>
  )
}

export default HomeSection2
